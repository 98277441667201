import React from 'react'
import {Helmet} from "react-helmet"
import '../styles/AmoChatScreen.css'
// import apps from '../build/bundle/apps'


function AmoChatScreen() {
  return (
    <div>
        <Helmet>
    <meta charset="utf-8" />
    <title></title>
<style>
{`
.header { grid-area: hd; 
    background-color: rgba(9, 7, 7, 1.0);
  
  }
  .main { grid-area: ma; 
  background-color: 2d373f;
   margin-left: auto;
   margin-right: auto;
  width:100% ;
  }
  .local { grid-area: ma; 
      
    width:20%;
    height:20%;
    align-self: end; /* bottom of column */
    justify-self: end; /* right of row */
    margin:1em;
    /* background-color: rgba(9, 7, 7, 0);*/
    background-color: rgba(255, 0, 0, 0);
  }
  .callapp_local_video{
      width: 100%;
      height: 100%;
  }
  .callapp_local_video > video{
      transform: scaleX(-1);
  }
  
  .callapp_remote_video{
      width: 75%;
      height: 100%;
  }
  
  .grid-container {
    display: grid;
    grid-template-areas:
      'hd hd hd hd'
      'ma ma ma ma'
      'ma ma ma ma'
      'ma ma ma ma';
    gap: 5px;
    background-color: #2d373f;
    color: #d5d5d5;
    padding: 10px;
  }
  
  .grid-container > div {
    padding: 20px 0;
    font-size: 20px;
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  h1 {
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  h2 {
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  }
  input {
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  
  }
  select {
    padding:10px;
   
    font-size: large;
    margin-left: 5%;
  }
  
  .passwordRow {
    
    margin-top:10px;
    margin-left: 5%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    width: 50%;
    
  }
  
  .turnDeviceNotification {
    position:fixed;
    top: 0;
    left:0;
    height:100%;
    width:100%;
    display: none;
    background-image:url('./assets/DontRotate.png');
    background-size:cover;
    text-align: center;
  }
`}
    </style>

<script>{`
    
    console.log("pppppppp")
    
    console.log("rrrrrrrrrrrrrrrr")
`}
</script>
        </Helmet>

    </div>
  )
}

export default AmoChatScreen