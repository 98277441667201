import { API, graphqlOperation } from 'aws-amplify'
import { getUserData } from '../../src/s48/IdentityManager'

const createDraftFromLiveMutation = /* GraphQL */ `
mutation createDraftFromLive(
  $obId: String!, 
  $sectok: String) {
  createDraftFromLive(
    obId: $obId,
    sectok: $sectok) {
    object {
      id
      propertiesData
      createdAt
      creator
      editor
      environment
      nameSpace
      obId
      objName
      order
      parentId
      prvid
      status
      updatedAt
    }
    response {
      statusCode
      message
      count
      detail
      hash
    }
  }
}
`

export const createDraftFromLive = async (obIdIn) => {
  try {
    const userData = await getUserData()
    const sectokData = userData.ability
    const objectData = await API.graphql(graphqlOperation(createDraftFromLiveMutation,
      {
        obId: obIdIn,
        sectok: sectokData
      }))
    const response = objectData.data.createDraftFromLive
    return response.object
  } catch (err) {
    console.log(`error executing newDraft:${JSON.stringify(err)}`)
  }
}