//import useFetch from "../useFetch";
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { listRegisteredProceduresPending, listAllQuestionsByParent, setQuestions } from "../store"
import { brandColours } from '../styles/BrandColours'
import Navbar from "./Navbar"

const VideoViewer = () => {
    //console.log("Im alive")
    const { opId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const src = "https://adlerbucket95720-dev.s3.eu-west-1.amazonaws.com/videos/HOLOLENS_tutorial_Video.mp4"

    //const data = dispatch(listRegisteredProceduresPending())
    useEffect(() => {
        dispatch(listAllQuestionsByParent(`GCOGN8Z09M_LIVE`))
        // dispatch(populateUsers())
       // objArray = store.evalQs

        
    }, [])
    return (
        <div className="container">
            <Navbar pageTitle="Video Tutorial" />
           
            <div >
           
            <video controls width="100%">
            <source src={src} type="video/mp4" />
      Sorry, your browser doesn't support embedded videos.
    </video>  
            </div>
        </div>
    )
}

export default VideoViewer