//import useFetch from "../useFetch";
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { listRegisteredProceduresPending } from "../store"
import { brandColours } from '../styles/BrandColours'
import Navbar from "./Navbar"

const AnatomyLocationScreen = () => {
    //console.log("Im alive")
    const { opId } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    //const data = dispatch(listRegisteredProceduresPending())

    return (   
        <div className="home">
    <Navbar pageTitle="Choose Location" />
        <div className="anatomyscreen">
         
            <div className="anatomypic">
                <img src={require("../assets/skel.png")} height="80%" />
            </div>
            <div className="anatomybuttons">
                <div>
                    <div className="anatomylabel" onMouseDown={() => console.log('hit')} style={{ color: 'lightgrey' }}>Shoulder</div>
                </div>
                <div>
                    <div className="anatomylabel" onMouseDown={() => console.log('hit')} style={{ color: 'lightgrey' }}>Elbow</div>
                </div>
                <div>
                    <div className="anatomylabel" onMouseDown={() => console.log('hit')} style={{ color: 'lightgrey' }}>Hand</div>
                </div>
                <div>
                    <div className="anatomylabel" onMouseDown={() => navigate('/proceduretypescreen')}>Hip</div>
                </div>
                <div>
                    <div className="anatomylabel" onMouseDown={() => console.log('hit')} style={{ color: 'lightgrey' }}>Knee</div>
                </div>
                <div>
                    <div className="anatomylabel" onMouseDown={() => console.log('hit')} style={{ color: 'lightgrey' }}>Foot</div>
                </div>
            </div>
        </div>
        </div>
    )
}

export default AnatomyLocationScreen