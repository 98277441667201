//import useFetch from "../useFetch";
import CustomProcedureItem from "./CustomProcedureItem"
import { useDispatch, useSelector } from "react-redux"
import { listAllProceduresByRootIdandNameSpace, listAllProceduresByRootId, populateUsers } from "./../store"
import { brandColours } from '../styles/BrandColours'
import Navbar from "./Navbar"
import { useEffect, useState } from "react"
import { set } from "lodash"


const ProcedureScreen = (props) => {
    const dispatch = useDispatch()
    const store = useSelector((state) => state.adler)
    const [procList, setProcList] = useState([])
    const { logOutput } = props
    const [filteredList, setFilteredList] = useState([])

    useEffect(() => {
       // dispatch(listAllProceduresByRootIdandNameSpace(process.env.REACT_APP_S48_ADLERROOTID, 'ADLER.PROCEDURE'))
        dispatch(listAllProceduresByRootIdandNameSpace(process.env.REACT_APP_S48_ADLERROOTID))
        dispatch(populateUsers())
    }, [])

    // useEffect(() => {
    //     //console.log("store.procedureSteps,", store.procedureSteps)
    //     setFilteredList(store.procedures.filter(function (item) { return item.nameSpace === 'ADLER.PROCEDURE' }))
    //     console.log('filteredlist', filteredList)
    // }, [])


    return (
        <>
        {/* <div className="procedurescreen"> */}
            <Navbar pageTitle="Procedures" />
            {store.procedures && <CustomProcedureItem customProcedureList={store.procedures} />}
           
        {/* </div>  */}
        </>
       
    )
}

export default ProcedureScreen