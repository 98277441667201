//import useFetch from "../useFetch";
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import { listRegisteredProceduresPending } from "../store"
import Navbar from "./Navbar"

const ProcedureTypeScreen = () => {
    //console.log("Im alive")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    //dispatch(listRegisteredProceduresPending())

    return (
        <div >
            <Navbar pageTitle="Choose Procedure Type" />
            <div style={{ display: 'flex', flexDirection: 'column', padding: '20px', marginTop: '10vh' }}>
                <div className="list-preview" style={{ color: 'lightgrey' }} onMouseDown={() => console.log('hit')} >Generic Procedures</div>
                <div className="list-preview" style={{ color: 'black' }} onMouseDown={() => navigate('/procedurescreen/')}>Custom Procedures</div>
            </div>
        </div>
    )
}

export default ProcedureTypeScreen