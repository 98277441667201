//import useFetch from "../useFetch";
import CustomProcedureItem from "./CustomProcedureItem"
import { useParams, useLocation } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setList } from "../redux/list"
import { store, listAllProceduresByRootId, listAllProceduresByParent } from "../store"
import { brandColours } from '../styles/BrandColours'
import Navbar from "./Navbar"
import { useEffect, useState } from "react"
import StepItem from "./StepItem"
import { listByParentId } from "../s48Data/AdsReactistObjectsByNameSpace_DAL"

const ProcedureStepsScreen = (props) => {
    const location = useLocation()
    const parentId = location.state.parentId
    //console.log(parentId)
    //console.log('customProc', parentId)

    const { store, logOutput, dispatch, listItems } = props

    let filteredList

    useEffect(() => {
        dispatch(listAllProceduresByParent(parentId))
    }, [])

    useEffect(() => {
        //console.log("store.procedureSteps,", store.procedureSteps)
        filteredList = store.procedureSteps.filter(function (item) { return item.nameSpace === 'ADLER.PROCEDURESTEP' })
    }, [store])

    //const { data, isPending, error } = useFetch('http://localhost:8001/procedures')
    // filter out the operations with the selected opId
    //const filteredData = data && data.filter(function (item) { return item.opId === opId })[0]

    // set the list to the store
    // data && dispatch(setList(JSON.stringify(filteredData)))

    return (
        <>
            <div className="home">
                <Navbar pageTitle="Procedure Steps" />
                {store.procedureSteps && <StepItem stepItems={store.procedureSteps} />}
            </div>
        </>
    )
}

export default ProcedureStepsScreen