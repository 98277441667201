import { API, graphqlOperation } from 'aws-amplify'
import { getUserData } from '../../src/s48/IdentityManager'

const saveDraftMutation = /* GraphQL */ `
mutation saveDraft(
  $id: String!, 
  $nameSpace: String, 
  $name1: String, 
  $propertiesData: AWSJSON, 
  $sectok: String) {
  saveDraft(
    id: $id, 
    name: $name1, 
    nameSpace: $nameSpace, 
    propertiesData: $propertiesData,
    sectok: $sectok) {
    object {
      creator
      updatedAt
      status
      prvid
      propertiesData
      parentId
      order
      objName
      obId
      nameSpace
      id
      environment
      editor
      createdAt
    }
    response {
      message
      statusCode
    }
  }
}
`
export const saveDraft = async (idIn, nameSpaceIn, nameIn, propertiesDataIn) => {
  try {
    const userData = await getUserData()
    const sectokData = userData.ability
    const objectData = await API.graphql(graphqlOperation(saveDraftMutation,
      {
        id: idIn,
        nameSpace: nameSpaceIn,
        name: nameIn,
        propertiesData: propertiesDataIn,
        sectok: sectokData
      }))
    const response = objectData.data.saveDraft
    return response.object
  } catch (err) {
    console.log(`error executing saveDraft:${JSON.stringify(err)}`)
  }
}