import { API, graphqlOperation } from 'aws-amplify'
import { getUserData } from '../../src/s48/IdentityManager'

const saveDraftToLiveMutation = /* GraphQL */ `
mutation saveDraftToLive(
  $id: String!, 
  $name: String, 
  $nameSpace: String, 
  $propertiesData: AWSJSON,
  $sectok: String) {
  saveDraftToLive(
    id: $id, 
    name: $name, 
    nameSpace: $nameSpace, 
    propertiesData: $propertiesData,
    sectok: $sectok) {
    object {
      createdAt
      creator
      editor
      environment
      id
      nameSpace
      obId
      objName
      order
      parentId
      propertiesData
      prvid
      readGroups
      status
      updatedAt
    }
    response {
      statusCode
      message
      hash
    }
  }
}
`

export const saveDraftToLive = async (idIn, nameSpaceIn, nameIn, propertiesDataIn) => {
  //console.log("SAVE: saveDraftToLive called for object");
  if (!idIn || idIn === "") { throw new Error("id is required") }
  if (!nameIn || nameIn === "") { throw new Error("name is required") }

  // const responseStructure = {
  //   response: {
  //     statusCode: -1,
  //     message: ""
  //   },
  //   objectData: {},
  //   _object: "NOTSET",
  //   get object() {
  //     if (this._object === "NOTSET") {
  //       this._object = ConstructObject(this.objectData)
  //     }
  //     return { ...this._object }
  //   }
  // }

  try {
    const userData = await getUserData()
    const sectokData = userData.ability
    const objectData = await API.graphql(graphqlOperation(saveDraftToLiveMutation,
      {
        id: idIn,
        nameSpace: nameSpaceIn,
        name: nameIn,
        propertiesData: propertiesDataIn,
        sectok: sectokData
      }))
    const response = objectData.data.saveDraftToLive
    responseStructure.objectData = response.object
    //responseStructure.response = response.response
    //console.log(responseStructure);
    return response.object
  } catch (err) {
    console.log(`error executing saveDraftToLiveRaw:${JSON.stringify(err)}`)
  }
}