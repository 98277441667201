import BlogList from "./BlogList"
import { ConstructEmptyObject } from "../s48/ObjectInterface"
import { setMocking, listRegisteredProceduresPending, listAllQuestionsByParent } from "../store"
import React, { useEffect, useState } from "react"
import Form from 'react-bootstrap/Form'
import { isPending } from "@reduxjs/toolkit"
import { brandColours } from '../styles/BrandColours'
import Navbar from "./Navbar"
import { useNavigate } from 'react-router-dom'
import { forEach, isNull } from "lodash"
import RangeSlider from 'react-bootstrap-range-slider'
import { Col, Row } from "react-bootstrap"
import { confirmAlert } from 'react-confirm-alert' // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css
//show the 
let x = 0
const EvalQuestion = (props) => {
const { store, logOutput, dispatch } = props 
const [ans, setAns] = useState([])
const [questions, setQuestions] = useState([])
const [question, setQuestion] = useState([])
const navigate = useNavigate()
const [value, setValue] = React.useState()


// let objArray = []


useEffect(() => {
      //  dispatch(listAllQuestionsByParent(`GCOGN8Z09M_LIVE`))
        // dispatch(populateUsers())
       // objArray = store.evalQs

       setQuestions(store.evalQs)
        questions.forEach((value) => {
            console.log('question:', questions.length)
            console.log(value.objName, JSON.parse(JSON.parse(value.propertiesData))?.type?.value ?? "no object")
            
            
        })
        
    }, [questions])

    useEffect(() => {
 x = 0
console.log("x", x)

    }, [])
    
async function saveObject(val) {


const goAhead = async () => {
 //setQuestion(questions[x].objName)
   let array = []
   array = ans
   array[x] = `value ${x} : ${value}`
   setAns(array)
console.log('ans i snow...', ans)

    let y = 97
const newobj = ConstructEmptyObject()

        newobj.objName = 'PreEval Feedback Record'
        newobj.nameSpace = `ADLER.FEEDBACK.PREEVAL.RECORD`
// below is the  ID of the ADLER.FEEDBACK.PREEVAL (Q33AMSKE1A_LIVE) namespace in which all the preeval records arte kept
        newobj.parentId = `Q33AMSKE1A_LIVE`
        val.forEach((value) => {
            newobj.setPropertyValue(`answer${String.fromCharCode(y)}`, value)
            y++
        })
        

        const savedFeedback = await newobj.saveDirectToLive()
        console.log('preEval feedback sent.....', savedFeedback)

        navigate('/mainmenu')
}
confirmAlert({
    title: 'Confirm save',
    message: 'Are you sure you want to do this.',
    buttons: [
      {
        label: 'Yes',
        onClick: () =>  goAhead()
      },
      {
        label: 'No'
        
      }
    ]
  })

}

 function goback() {
    console.log('back')
    setValue()
    x--
    try {
        setQuestion(questions[x - 1].objName)
     } catch (error) {
        console.log('errror', error.message)
     }

 }

function showSlider() {


const qtype = JSON.parse(JSON.parse(questions[x - 1].propertiesData)).type.value
switch (qtype) {
case 'val':
    return (<div >
        <Form.Group as={Row}>
           <Form.Control value={value ?? ''}  style={{width:'20vw', alignSelf:'center', margin:'10px'}}/>
                <RangeSlider
                    value={value}
                    max={10}
                    onChange={e => setValue(e.target.value)} 
                    variant='success'/>         

        </Form.Group> 
        <div style ={{display:'flex', justifyContent:'space-between'}}><div>{JSON.parse(JSON.parse(questions[x - 1].propertiesData))?.min?.value ?? "0"} </div><div> {JSON.parse(JSON.parse(questions[x - 1].propertiesData))?.max?.value ?? "10"}</div></div>
        <div style ={{display:'flex', justifyContent:'space-between'}}><div>{JSON.parse(JSON.parse(questions[x - 1].propertiesData)).min ? "0" : "" } </div><div> {JSON.parse(JSON.parse(questions[x - 1].propertiesData)).min ? "10" : "" }</div></div>

        </div>
        )
    break

case 'text':
    return ( 
        <textarea value={value ?? ''} onChange={e => setValue(e.target.value)} placeholder="Enter your response here"/>     
      )
    break

case 'tf':
    const onOptionChange = e => {
        setValue(e.target.value)
      }
    return ( 
        <div className="tfContainer">
        <div className="tfRow"><input type='radio'  value='true' checked={value === "true"} id='true' onChange={onOptionChange}/><h3> Yes </h3></div>
        <div className="tfRow"><input type='radio' value='false' checked={value === "false"} onChange={onOptionChange}/><h3> No </h3></div>
        </div> 
      )
    break

default:
    return ( 
        <textarea value={value ?? ''} onChange={e => setValue(e.target.value)} />     
      )
    break

}
}


function clicked() {
    
if (x < questions.length - 1) { console.log('end NOT reached') } else { console.log('end reached') }

 //setQuestion(questions[x].objName)
   let array = []
   array = ans
   if (x === 0) { array[x] = `value ${x} : start` } else {
 array[x] = `value ${x} : ${value}`
}
   
   setAns(array)
   setValue()
     try {
        setQuestion(questions[x].objName)
     } catch (error) {
        console.log('errror', error.message)
     }
   x++

   console.log(x)
}
    
if (question.length !== 0) {
return (
       x,
        <div className="container" >
            
           <Navbar pageTitle={question.length !== 0 ? `Question ${x}` : 'Ready to begin?'} />
           { x > 1 ? <div className='whiteButton' onClick={() => { 
        goback()
        }}>Back</div> : <div style={{margin:'50px'}}>.   </div>
    }     
           <Form className = 'questionContainer'>
          
<div className="h2">{question}</div>

   { showSlider()}
  
                    </Form>
 : <div></div> 
             
    
             {console.log('value: ', value)}
            { x > questions.length - 1 ? <div className='orangelink' onClick={() => saveObject(ans)}>SAVE </div> : value ? <div className="container"><div className='blueButton' onClick={clicked}>Submit</div> </div> : <div className="container"> <div>..</div></div>
            }
        </div>
    )
        } else {
            return (
                <div className="container" >
            
                <Navbar pageTitle={'Ready to begin?'} />
                <div className='greenButton' onClick={clicked}>Go</div>
                </div>
            )
        }
}

export default EvalQuestion