import { API, graphqlOperation } from 'aws-amplify'
import { getUserData } from '../../src/s48/IdentityManager'
import { dalLog, dalLogDebug, errorLog } from '../../src/s48/logger'

const saveDirectToLiveMutation = /* GraphQL */ `
mutation saveDirectToLive(
  $assignedToGroupId: ID,
  $assignedToId: ID,
  $obId: String, 
  $parentId: String,
  $name: String, 
  $nameSpace: String, 
  $propertiesData: AWSJSON, 
  $sectok: String)
  {
  saveDirectToLive(obId: $obId, 
  parentId: $parentId,
  assignedToGroupId: $assignedToGroupId, 
  assignedToId: $assignedToId, 
  name: $name, 
  nameSpace: $nameSpace, 
  propertiesData: $propertiesData,
  sectok: $sectok) {
    object {
      creator
      updatedAt
      status
      prvid
      propertiesData
      parentId
      order
      objName
      obId
      nameSpace
      id
      environment
      editor
      createdAt
      assignStart
      assignDeadline
      assignNote
      assignUrgency
      assignedById
      assignUrgency
      assignedToGroupId
      assignedToGroupName
      assignedToId
      assignedToName
    }
    response {
      message
      statusCode
    }
  }
}
`

export const saveDirectToLive = async (obIdIn, nameSpaceIn, nameIn, propertiesDataIn, parentIdIn, assignedToIdIn, assignedToGroupIdIn) => {
  //dalLog("saveDirectToLive (RAW) (obIdIn, nameSpaceIn, nameIn, propertiesDataIn, parentIdIn, assignedToIdIn, assignedToGroupIdIn)")
  try {
    const userData = await getUserData()
    const sectokData = userData.ability
    const objectData = await API.graphql(graphqlOperation(saveDirectToLiveMutation,
      {
        obId: obIdIn,
        parentId: parentIdIn,
        nameSpace: nameSpaceIn,
        name: nameIn,
        propertiesData: propertiesDataIn,
        assignedToId: assignedToIdIn,
        assignedToGroupId: assignedToGroupIdIn,
        sectok: sectokData
      }))
    const response = objectData.data.saveDirectToLive
    //dalLogDebug("saveDirectToLive (RAW) Response", response)
    //dalLogDebug("saveDirectToLive (RAW) object", response.object)
    return response.object
  } catch (err) {
    errorLog(`saveDirectToLiveRaw_DAL -  error executing saveDirectToLiveRaw:`, err)
    errorLog(`error message`, err.message)
    console.log(`error executing saveDirectToLiveRaw:${JSON.stringify(err)}`)
    throw new Error("Snap", err.message)
  }
}

export const saveDirectToLiveObj = async (object) => {
  //console.log("saveDirectToLiveRaw_DAL - saveDirectToLiveObj", object)
  try {
    return await saveDirectToLive(
      object.obId,
      object.nameSpace,
      object.objName,
      object.getPropertiesAsJson(),
      object.parentId,
      object.assignedToId,
      object.assignedToGroupId
    )
  } catch (error) {
    console.log("ERROR! saveDirectToLiveRaw_DAL - saveDirectToLiveObj", error)
  }
}