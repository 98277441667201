
import { listByParent } from '../s48Data/listObjectsByParent_DAL'
import { listByNameSpace } from '../s48Data/listObjectsByNameSpace_DAL_V2'
import { getLoginUserAbilites } from '../s48Data/getUserAbilites_DAL'
import { ConstructObject } from './ObjectInterface'
import { Buffer } from 'buffer'
//import { constrainPoint } from '@fullcalendar/react'

export class AbilityManager {
  ShrinkAbilitiesArray(abilites) {
    //console.log("ShrinkAbilitiesArray", abilites)
    const compactAbils = []
    abilites.forEach((abil) => {
      compactAbils.push({ a: abil.action, s: abil.subject })
    })
    //console.log("ShrinkAbilitiesArray", compactAbils)
    return compactAbils
  }

  async GetAbilitesFromAPI(api) {
    return await getLoginUserAbilites(api)
  }

  // async GetDecryptedAbilitesFromAPI(secKey) {
  //   const encAbils = await this.GetEncyptedAbilitesFromAPI()
  //   const decryptedAbils = this.DecryptAbilites(encAbils, secKey)
  //   return decryptedAbils
  // }

  // DecryptAbilites(text, secKey) {
  //   let crypto
  //   try {
  //     crypto = require('crypto')
  //   } catch (err) {
  //     console.log('crypto support is disabled!')
  //   }

  //   const algorithm = 'aes-256-ctr'
  //   //prepare sec key - enaure correct length
  //   const hexkey = Buffer.from(secKey, 'utf8') //secKey.slice(-64)
  //   const hextext = hexkey.toString('hex')
  //   const ENCRYPTION_KEY = hextext.slice(-64)

  //   const parts = text.split(':')
  //   const iv = Buffer.from(parts.shift(), 'hex')
  //   const encryptedText = Buffer.from(parts.join(':'), 'hex')
  //   const decipher = crypto.createDecipheriv(algorithm, Buffer.from(ENCRYPTION_KEY, 'hex'), iv)
  //   let decrypted = decipher.update(encryptedText)
  //   decrypted = Buffer.concat([decrypted, decipher.final()])
  //   return decrypted.toString()
  // }

  // DecryptAbilitesToArray(text, secKey) {
  //   const decryptedAbsText = this.DecryptAbilites(text, secKey)
  //   let absArray = JSON.parse(decryptedAbsText)
  //   //want to take out the xiid as its only needed in the sectok
  //   absArray = absArray.filter(item => item.action !== "xiid")
  //   //absArray = absArray.filter(item => item.action !== "xorg")
  //   return absArray
  // }

  async DeriveAbilitesFromRoleIDList(roleIds) {
    const response = await listByParent(process.env.REACT_APP_S48_ROLESFOLDERID)
    const matches = []
    //console.log("RoleIDs", roleIds)
    //console.log("Roles", response)
    response.forEach((obj) => {
      roleIds.forEach((role) => {
        if (role.value === obj.object.obId) {
          matches.push(obj.object)
        }
      })
    })
    //console.log("matched roles", matches)
    let abilities = []
    matches.forEach((obj) => {
      const fullObj = ConstructObject(obj)
      const objAbilities = fullObj?.properties?.abilities?.value ?? []
      const objAbilsArray = JSON.parse(objAbilities)
      //console.log("Abilities", objAbilsArray.abilities)
      abilities = abilities.concat(objAbilsArray.abilities)
    })
    console.log("Abilities", abilities)
    abilities = abilities.filter((v, i, a) => a.findIndex(v2 => ['action', 'subject'].every(k => v2[k] === v[k])) === i)
    abilities.sort((a, b) => (((a.action + a.subject) > (b.action + b.subject)) ? 1 : (((b.action + b.subject) > (a.action + a.subject)) ? -1 : 0)))
    return abilities
  }
}

export default AbilityManager