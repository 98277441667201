import React, { useCallback, useState } from "react"
import { FaStar } from 'react-icons/fa'
import { Star, ThreeDRotation } from '@mui/icons-material'
import { brandColours } from "../styles/BrandColours"


const StarRating = (props) => {

     
    const [currentValue, setCurrentValue] = useState(0)
    const [hoverValue, setHoverValue] = useState(0)
    const stars = Array(5).fill(0)

    const setLocalVal = value => {
        props.handleClick(value.toString())
        setCurrentValue(value)
    }

    const handleMouseOver = value => {
        setHoverValue(value)
    }

    const handleMouseLeave = () => {
        setHoverValue(undefined)
    }
    
    return (
        <div className="star-rating">
            {stars.map((_, index) => {

                return (
                    <Star
                        size={8}
                        key={index}
                        style={{ fill: (hoverValue || currentValue) > index ? brandColours.adlerAlert : "grey", fontSize: "1.5em", marginRight: '10px' }}
                        onClick={() => { setLocalVal(index + 1) }}
                        onMouseOver={() => { handleMouseOver(index + 1) }}
                        onMouseLeave={() => { handleMouseLeave(index + 1) }}
                    >
                    </Star>
                )
            })}
        </div >
    )
}

export default StarRating