// import AsyncStorage from "@react-native-async-storage/async-storage"
import {
  debugLog,
  infoLog,
  useEffectLog,
  useEffectLogDebug,
  warningLog
} from "../s48/logger"

export const getUserData = async () => {
  const userData = JSON.parse(localStorage.getItem('userData'))
  return userData
}

// const platform = "RN"

// export const setUserData = async (dataObject) => {
//   //
//   if (platform === "RN") {
//     try {
//       const jsonEncodedData = JSON.stringify(dataObject)
//       await AsyncStorage.setItem("@userData", jsonEncodedData)
//     } catch (error) {
//       console.log("ERROR! setUserData", error)
//     }
//   }
// }

// export const setUserDataToNull = async () => {
//   //infoLog("Trying to clear userData")
//   if (platform === "RN") {
//     try {
//       infoLog("Clearing user data")
//       const jsonEncodedData = JSON.stringify({})
//       await AsyncStorage.setItem("@userData", jsonEncodedData)
//     } catch (error) {
//       errorLog("ERROR! setUserData", error)
//     }
//   }
// }

// export const getUserData = async () => {
//   if (platform === "RN") {
//     try {
//       const userDataJson = await AsyncStorage.getItem("@userData")
//       if (userDataJson !== null) {
//         try {
//           const userData = JSON.parse(userDataJson)

//           return userData
//         } catch (error) {
//           console.log("ERROR! getUserData", error)
//         }
//       }
//     } catch (error) {
//       console.log("ERROR! getUserData", error)
//     }
//   }
// }
