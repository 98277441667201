import { Padding } from "@mui/icons-material"
import React from "react"


export default function LoadingSpinner() {
    return (
        <div className="spinner-container" style={{ marginLeft: 'auto', marginRight: 'auto', padding: '20px'}}>
            <div className="loading-spinner">
            </div>
        </div>
    )
}