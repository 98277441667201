
import { useNavigate } from "react-router-dom"
import { brandColours } from '../styles/BrandColours'
import Navbar from "./Navbar"
import CheckboxListSecondary from "../extracomponents/CheckBoxListSecondary"
import DatePicker from "react-datepicker"
import { useState, useEffect } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentProcedure, updateProcedure } from "../store"
import moment from 'moment'
import LoadingSpinner from "../extracomponents/LoadingSpinner"

import Select from 'react-select'

import "react-datepicker/dist/react-datepicker.css"


const EditRegisteredOpScreen = () => {
    const dispatch = useDispatch()
    const store = useSelector((state) => state.adler)
    const [opDate, setOpDate] = useState(new Date())
    const [formData, setFormData] = useState()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => { }, [store.surgUserSelectOptions])

    useEffect(() => {
        if (store.currentProcedure) {
            //console.log('use effect ', store.currentProcedure)
            setFormData(store.currentProcedure.getCloneDeep())
            //console.log('op date', moment(store.currentProcedure.properties.opdate.value).format('ddd MMM D YYYY h:mm:ss z'))
            const date = new Date(store.currentProcedure.properties.opdate.value)
            //console.log('date', date)
            setOpDate(date)
        }
    }, [store.currentProcedure])

    const setDate = (date) => {
        setOpDate(date)
        const obj = formData.getCloneDeep()
        // obj.setPropertyValue(fieldKeySelect, JSON.stringify(option))
        obj.setPropertyValue('opdate', date)
        console.log('set value..post edit.', obj)
        setFormData(obj)
    }

    const surgOptions = [{ value: 'ad', label: 'Advait Gandhe' }]
    const scrubOptions = [{ value: 'mwallis', label: 'Matthew Wallis' }]
    const repOptions = [{ value: 'ollie', label: 'Ollie Wylie' }]
    const hospOptions = [{ value: 'QAH', label: 'Portsmouth Hospitals University Trust' }]

    function setValue(fieldKeySelect, fieldKeyValue, option) {
        const obj = formData.getCloneDeep()
        obj.setPropertyValue(fieldKeySelect, JSON.stringify(option))
        obj.setPropertyValue(fieldKeyValue, option.value)
        console.log('set value..post edit.', obj)
        setFormData(obj)
    }

    function handleSelectSurg(option) {
        setValue('surgeonselect', 'surgeon', option)
    }

    function handleSelectScrub(option) {
        setValue('scrubselect', 'scrub', option)
    }

    function handleSelectRep(option) {
        setValue('repselect', 'rep', option)
    }

    function handleSelectHosp(option) {
        setValue('hospselect', 'hospital', option)
    }

    async function submitAll() {
        setIsDisabled(true)
        setIsLoading(true)
        const savedProcedure = await formData.saveDirectToLive()
        dispatch(setCurrentProcedure(savedProcedure))
        dispatch(updateProcedure(savedProcedure))
        navigate(-1)
        setIsDisabled(false)
    }

    return (
        <>
            <Navbar pageTitle="Edit Registered Op" />
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', height: '80vh', marginTop: '10vh' }}>
                <div style={{ display: 'flex', flexDirection: 'column', color: brandColours.adlerBlue, fontWeight: 'bold' }}>Please choose
                    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                        <h3>Surgeon</h3>
                        <Select options={store.surgUserSelectOptions} value={JSON.parse(formData?.properties?.surgeonselect?.value ?? '{}')} onChange={handleSelectSurg} />
                    </div>
                    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                        <h3>Theatre Practitioner</h3>
                        <Select options={store.scrubUserSelectOptions} value={JSON.parse(formData?.properties?.scrubselect?.value ?? '{}')} onChange={handleSelectScrub} />
                    </div>
                    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                        <h3>Account Manager</h3>
                        <Select options={store.repUserSelectOptions} value={JSON.parse(formData?.properties?.repselect?.value ?? '{}')} onChange={handleSelectRep} />
                    </div>
                    <div style={{ marginTop: '10px', marginBottom: '20px' }}>
                        <h3>Hospital</h3>
                        <Select options={store.hospitals} value={JSON.parse(formData?.properties?.hospselect?.value ?? '{}')} onChange={handleSelectHosp} />
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div style={{ marginBottom: '20px', color: brandColours.adlerBlue, fontWeight: 'bold' }}>Please select the operation date</div>
                    <DatePicker dateFormat="d MMM yyyy" selected={opDate} onChange={(date) => setDate(date)} />
                </div>
                {isDisabled ? <div style={{ textAlign: 'center', color: brandColours.adlerGrey, fontSize: 'x-large' }} onClick={() => { console.log('waiting') }}>Please wait...</div> : <div style={{ textAlign: 'center', color: brandColours.adlerAlert, fontSize: 'x-large' }} onClick={() => { submitAll() }}>Submit</div>}
                {isLoading ? <LoadingSpinner /> : <></>}
            </div>
        </>
    )
}

export default EditRegisteredOpScreen