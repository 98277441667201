import React, { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import LoginScreen from './screens/Login'
import Home from './screens/OpRegScreen'
import { Redirect, Route, Routes, useNavigate } from 'react-router-dom'
import AnatomyLocationScreen from './screens/AnatomyLocationScreen'
import ProcedureTypeScreen from './screens/ProcedureTypeScreen'
import ProcedureScreen from './screens/ProcedureScreen'
import ProcedureStepsScreen from './screens/ProcedureStepsScreen'
import StepDisplay from './screens/StepDisplay'
import EvalMenu from './screens/EvalMenu'
import EvalQuestion from './screens/EvalQuestion'
import EditRegisteredOpScreen from "./screens/EditRegisteredOpScreen"
import Navbar from './screens/Navbar'
import MainMenu from './screens/MainMenu'
import VideoViewer from './screens/VideoViewer'
import VideoWebCaller from './screens/VideoWebCaller'
import AmoChatScreen from './screens/AmoChatScreen'

const Router = (props) => {
  const { store, dispatch } = props
  // const [authenticated, setAuthenticated] = useState(false)


  // useEffect(() => {
  //   console.log("Routes useEffect")
  //   Auth.currentAuthenticatedUser()
  //     .then(() => {
  //       setAuthenticated(true)
  //     })
  //     .catch(() => {
  //       setAuthenticated(false)
  //     })
  // }, [])

  return (
    <Routes>
      <Route exact path="/" element={<LoginScreen store={store} dispatch={dispatch} logOutput={true} />} />
      {/* <Route exact path="/back" element={<Back store={store} dispatch={dispatch} logOutput={true} />} /> */}
      {Auth.currentAuthenticatedUser() ? (<>

        <Route path="/home" element={<Home store={store} dispatch={dispatch} logOutput={true} />} />
        <Route path="/mainmenu" element={<MainMenu store={store} dispatch={dispatch} logOutput={true} />} />
        <Route path="/videoviewer" element={<VideoViewer store={store} dispatch={dispatch} logOutput={true} />} />
        <Route path="/videowebcaller" element={<VideoWebCaller store={store} dispatch={dispatch} logOutput={true} />} />
        <Route path="/anatomylocationscreen" element={<AnatomyLocationScreen store={store} dispatch={dispatch} logOutput={true} />} />
        <Route path="/proceduretypescreen" element={<ProcedureTypeScreen store={store} dispatch={dispatch} logOutput={true} />} />
        <Route path="/procedurescreen" element={<ProcedureScreen store={store} dispatch={dispatch} logOutput={true} />} />
        <Route path="/procedurestepsscreen" element={<ProcedureStepsScreen store={store} dispatch={dispatch} logOutput={true} />} />
        {/* <Route path="/procedurescreen/:opId" element={<ProcedureScreen store={store} dispatch={dispatch} logOutput={true} />} /> */}
        <Route path="/editregisteredopscreen" element={<EditRegisteredOpScreen store={store} dispatch={dispatch} logOutput={true} />} />
        <Route path="/evalmenu" element={<EvalMenu store={store} dispatch={dispatch} logOutput={true} />} />
        <Route path="/evalquestion" element={<EvalQuestion store={store} dispatch={dispatch} logOutput={true} />} />
        <Route path="/amochatscreen" element={<AmoChatScreen store={store} dispatch={dispatch} logOutput={true} />} />
        <Route path="/stepdisplay" element={<StepDisplay store={store} dispatch={dispatch} logOutput={true} />} />
      </>) : null}
      <Route element={<LoginScreen />} />
    </Routes>
  )
}

export default Router
