import { Link, useNavigate } from "react-router-dom"
import { listByParentId } from "../s48Data/AdsReactistObjectsByNameSpace_DAL"
import { brandColours } from "../styles/BrandColours"
import Navbar from "./Navbar"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setCurrentProcedure, setCurrentStep, updateProcedure } from "../store"

// import { ConstructObject } from "./ObjectInterface"
// display each procedure item here
const StepItem = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const store = useSelector((state) => state.adler)

    const handleClick = (obId) => {
        //console.log(obId)
        // sync the admin step number
        // step number is listItem.object.order
        ///////////////////////
    }

    const movetoStepDisplay = async (listItem) => {

        console.log('dispatching ....', listItem)
        dispatch(setCurrentStep(listItem))
        navigate("/stepdisplay")
        // const procedure = store.currentProcedure.getCloneDeep()
        // procedure.setPropertyValue('synchedstepid', listItem.order)
        // const savedItem = await procedure.saveDirectToLive()

        // dispatch(updateProcedure(savedItem))
        // dispatch(setCurrentProcedure(savedItem))

    }

    if (store.procedureSteps) {
        // console.log('rendering...')
        return (
            <>
                {/* <Navbar pageTitle="Procedure Steps" /> */}
                {/* <h2>{listItems.object}</h2> */}

                <div className="proc-list">
                    {store.procedureSteps.map((stepItem) => {


                        return (

                            <div className={stepItem.order === store.currentProcedure.properties.synchedstepid.value ? "list-preview-highlight" : "list-preview"} key={stepItem.id} onClick={() => movetoStepDisplay(stepItem)}>

                                {/* <h2 style={listItem.object.order === '-1' ? { color: "lightgrey" } : { color: brandColours.adlerBlue }}>{listItem.object.objName}</h2> */}
                                <h3 style={stepItem.order === '-1' ? { color: "lightgrey" } : { color: "black" }}>{stepItem?.properties.instruction.value ?? ""}</h3>
                                <p> {stepItem?.properties.detail.value ?? ""}</p>
                                <div className="listItem">

                                    <div className="columnLeft">

                                        <img src={stepItem?.properties.scruburl.value ?? ""} width="100vh" />
                                    </div>
                                    <div className="columnMiddle" style={{ color: brandColours.adlerBlue, textAlign: 'center' }}>
                                        Select
                                    </div>
                                    <div className="columnRight">
                                        <img src={stepItem?.properties.surgurl.value ?? ""} width="100vh" />
                                    </div>

                                </div>


                            </div>
                        )
                    })
                    }
                </div ></>


        )


    }
}
export default StepItem