import { API, graphqlOperation } from 'aws-amplify'
import { ConstructObject } from '../s48/ObjectInterface'
import { buildQuery } from './gqlQueryObject'

const gqlQueryParams = {
  queryName: "listByParentAndName",
  partitionKey: "parentId",
  inputParams: {
    parentId: "String!",
    simpleReturn: "Boolean",
    excludeProps: "Boolean",
    countOnly: "Boolean",
    limit: "Int",
    lastKey: "String",
    filterValue: "String",
    hash: "String"
  }
}

export const listByParentAndNameSpace = async (
  partitionValueIn,
  sortFilterIn = "",
  limitIn = 100,
  simpleReturnIn = false,
  excludePropsIn = false,
  countOnlyIn = false,
  lastKeyIn = "NA"
) => {
  gqlQueryParams.queryName = "listByParentAndNameSpace"
  //console.log(`listByParentAndNameSpace called for object for ${gqlQueryParams.partitionKey}: ${partitionValueIn}`)
  const queryString = await buildQuery(gqlQueryParams)
  try {
    const query = await API.graphql(graphqlOperation(queryString,
      {
        parentId: partitionValueIn, //#############  how  to  do generic.. this is the  
        filterValue: sortFilterIn,
        limit: limitIn,
        simpleReturn: simpleReturnIn,
        excludeProps: excludePropsIn,
        countOnly: countOnlyIn,
        lastKey: lastKeyIn
      }))
    const results = query.data[gqlQueryParams.queryName]
    return results
  } catch (err) {
    console.log("ERROR listByParentAndNameSpace ######################################")
    console.log(err)
    console.log("ERROR END ############################################################")
  }
}

export const listByParentAndName = async (
  partitionValueIn,
  sortFilterIn = "",
  limitIn = 100,
  simpleReturnIn = false,
  excludePropsIn = false,
  countOnlyIn = false,
  lastKeyIn = "NA"
) => {
  const queryName = "listByParentAndNameSpace"
  gqlQueryParams.queryName = queryName
  //console.log(`listByParentAndName called for object for ${gqlQueryParams.partitionKey}: ${partitionValueIn}`)
  const queryString = await buildQuery(gqlQueryParams)
  try {
    const query = await API.graphql(graphqlOperation(queryString,
      {
        parentId: partitionValueIn, //#############  how  to  do generic.. this is the  
        filterValue: sortFilterIn,
        limit: limitIn,
        simpleReturn: simpleReturnIn,
        excludeProps: excludePropsIn,
        countOnly: countOnlyIn,
        lastKey: lastKeyIn
      }))
    const results = query.data[queryName]
    return results
  } catch (err) {
    console.log("ERROR listByParentAndName ######################################")
    console.log(err)
    console.log("ERROR END ############################################################")
  }
}
export const listByParentAndNameandNameSpace = async (
  partitionValueIn,
  nameSpaceIn,
  sortFilterIn = "",
  limitIn = 100,
  simpleReturnIn = false,
  excludePropsIn = false,
  countOnlyIn = false,
  lastKeyIn = "NA"
) => {
  const queryName = "listByParentAndNameSpace"
  gqlQueryParams.queryName = queryName
  //console.log(`listByParentAndName called for object for ${gqlQueryParams.partitionKey}: ${partitionValueIn}`)
  const queryString = await buildQuery(gqlQueryParams)
  try {
    const query = await API.graphql(graphqlOperation(queryString,
      {
        parentId: partitionValueIn, //#############  how  to  do generic.. this is the  
        nameSpace: nameSpaceIn,
        filterValue: sortFilterIn,
        limit: limitIn,
        simpleReturn: simpleReturnIn,
        excludeProps: excludePropsIn,
        countOnly: countOnlyIn,
        lastKey: lastKeyIn
      }))
    const results = query.data[queryName]
    return results
  } catch (err) {
    console.log("ERROR listByParentAndName ######################################")
    console.log(err)
    console.log("ERROR END ############################################################")
  }
}

export const listByParentAndOrder = async (
  partitionValueIn,
  sortFilterIn = "",
  limitIn = 100,
  simpleReturnIn = false,
  excludePropsIn = false,
  countOnlyIn = false,
  lastKeyIn = "NA"
) => {
  const queryName = "listByParentAndOrder"
  gqlQueryParams.queryName = queryName
  //console.log(`listByParentAndName called for object for ${gqlQueryParams.partitionKey}: ${partitionValueIn}`)
  const queryString = await buildQuery(gqlQueryParams)
  try {
    const query = await API.graphql(graphqlOperation(queryString,
      {
        parentId: partitionValueIn, //#############  how  to  do generic.. this is the  
        filterValue: sortFilterIn,
        limit: limitIn,
        simpleReturn: simpleReturnIn,
        excludeProps: excludePropsIn,
        countOnly: countOnlyIn,
        lastKey: lastKeyIn
      }))
    const results = query.data[queryName]
    return results
  } catch (err) {
    console.log("ERROR listByParentAndOrder ######################################")
    console.log(err)
    console.log("ERROR END ############################################################")
  }
}
