import { API, graphqlOperation } from "aws-amplify"
import { getUserData } from "../../src/s48/IdentityManager"
import { dalLog, dalLogDebug, errorLog, warningLog } from '../s48/logger'

//NOTE: this query is protected by security at the back end
const listByParentQuery = /* GraphQL */ `
  query listByParent($parentId: String!, $nameSpace: String, $sectok: String) {
    listByParent(parentId: $parentId, nameSpace: $nameSpace, sectok: $sectok) {
      objects {
        object {
          obId
          createdAt
          creator
          editor
          environment
          id
          nameSpace
          objName
          order
          parentId
          propertiesData
          prvid
          status
          updatedAt
        }
      }
      response {
        count
        detail
        hash
        message
        statusCode
      }
    }
  }
`

export const listByParent = async (parentIdIn, nameSpaceIn) => {
  try {
    const userData = await getUserData()
    const sectokData = userData.ability
    if (sectokData === undefined) {
      warningLog("userData does not have sectok - query cannot run")
      return null
    }
    const objectData = await API.graphql(
      graphqlOperation(listByParentQuery, {
        parentId: parentIdIn,
        nameSpace: nameSpaceIn,
        sectok: sectokData
      })
    )
    const objects = objectData.data.listByParent.objects
    //dalLog(`List by parent. ${parentIdIn} Count:`, objects.length)
    return objects
  } catch (err) {
    console.log("ERROR listByParent ######################################")
    console.log(`input - parentIdIn ${parentIdIn}`)
    console.log(err)
    console.log(
      "ERROR END ############################################################"
    )
  }
}
