
import { Link, useNavigate } from "react-router-dom"
import { Auth } from "aws-amplify"
import { brandColours } from './../styles/BrandColours'

const Navbar = (props) => {
    const pageTitle = props.pageTitle
    const navigate = useNavigate()
    const goBack = () => {

        navigate(-1)

    }
    return (

        <nav className="navbar" >
            <div style={{ color: brandColours.adlerAlert, marginRight: '0px', marginLeft: '0px' }} onClick={goBack}> {'\u2190'} </div>
            <h1>{pageTitle ?? 'Remote Support'}</h1>
            <div className="links">

                {/* <Link to="/proceduresteps" style={{
                    color: 'white',
                    backgroundColor: 'grey',
                    borderRadius: 5,
                }}>2</Link> */}
            </div>
        </nav>

    )
}

export default Navbar