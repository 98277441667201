import { getUserData } from '../../src/s48/IdentityManager'

export const objectDefFull = /* GraphQL */ `
object {
        createdAt
        creator
        editor
        environment
        id
        nameSpace
        obId
        objName
        order
        parentId
        propertiesData
        prvid
        status
        updatedAt
        assignStart
        assignedById
        assignedToGroupId
        assignedToGroupName
        assignedToId
        assignedToName
        assignNote
        assignUrgency
        assignDeadline
      }
`

export const responseDef =  /* GraphQL */ `
response {
      count
      detail
      hash
      message
      statusCode
      lastEval
    }
`

function buildInputPartOne(params) {
    let input = ``
    for (const [key, value] of Object.entries(params.inputParams)) {
        input = `${input}${"$"}${key}: ${value}, `
    }
    return input
}

function buildInputPartTwo(params) {
    let input = ``
    for (const [key] of Object.entries(params.inputParams)) {
        input = `${input}${key}: ${"$"}${key}, `
    }
    return input
}

export const buildQuery = async (params) => {
    try {
        const userData = await getUserData()
        const sectokData = userData.ability
        //console.log("buildQuery")
        const partOne = buildInputPartOne(params)
        const partTwo = buildInputPartTwo(params)
        const query = `
query ${params.queryName}(
  ${partOne}
  ) {
    ${params.queryName}(
    ${partTwo}
    sectok: "${sectokData}") {
objects {
${objectDefFull}
}
${responseDef}
}
}`
        //console.log("query", query)
        return query
    } catch (error) {
        console.error(error)
    }
}