import { API, graphqlOperation } from 'aws-amplify'
import { debugLog, dalLog } from "../s48/logger"
import { getUserData } from '../../src/s48/IdentityManager'
//this is a very special call
//only the logged in user can get this info back
//it sets the abilities for the rest of the session
const getLoginUserAbilitesQuery = /* GraphQL */ `
query getLoginUserAbilites ($apiid: String!) {
  getLoginUserAbilites(apiid: $apiid) {
    encab
    response {
      count
      message
      statusCode
    }
  }
}
`

export const getLoginUserAbilites = async (apiIn) => {
  let appsyncAPI = ""
  if (apiIn !== undefined && apiIn !== null && apiIn !== "") {
    appsyncAPI = apiIn
  } else {
    appsyncAPI = process.env.REACT_APP_S48_APIID
  }
  //dalLog("API ID", appsyncAPI)
  try {
    const abilityData = await API.graphql(graphqlOperation(getLoginUserAbilitesQuery,
      {
        apiid: appsyncAPI
      }))

    const abilities = abilityData.data.getLoginUserAbilites
    //console.log(abilities)
    return abilities
  } catch (err) {
    console.error(`error executing getLoginUserAbilites:${JSON.stringify(err)}`)
  }
}
