import BlogList from "./BlogList"
import { setMocking, listRegisteredProceduresPending } from "../store"
import { useEffect } from "react"
import { isPending } from "@reduxjs/toolkit"
import { brandColours } from '../styles/BrandColours'
import Navbar from "./Navbar"
//show the 
const OpRegScreen = (props) => {
    const { store, logOutput, dispatch } = props
    if (logOutput === true) { console.log("OpRegScreen", store.data) }
    useEffect(() => {
        dispatch(setMocking(true))
    }, [])
    useEffect(() => {
        //dispatch(listRegisteredProceduresPending())
    }, [])

    return (
        <div className="home" >
            <Navbar pageTitle="Registered Operations" />
            HOME {store.loadText} {store.mockData.toString()}

            {/* {error && <div>{error}</div>} */}
            {/* {isPending && <div>Loading...</div>} */}
            {/* {data && <BlogList listItems={data} />} */}
        </div>
    )
}

export default OpRegScreen