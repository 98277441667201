const enabled = true
const debugEnabled = true

const logInfo = true //INFO
const logSecurity = true //SECURITY
const logDal = true //DAL

const logDalDebug = true //DALDEBUG
const logMiddleware = true //MIDDLE
const logStore = true //STORE
const logStoreDebug = true //STOREDEBUG
const logNote = true  //NOTE
const logDebug = true  //DEBUG
const logUseEffect = true  //USEEFFECT
//const logUseEffectDebug = true  //USEEFFECT
const logOthers = true // any other
const logWarning = true  //WARNING
const logErrors = true  //ERROR
//const logQuestions = true  //QUESTIONS
const stringifyObjects = true

const reset = "\x1b[0m"
//const bright = "\x1b[1m"
///const dim = "\x1b[2m"
//const underscore = "\x1b[4m"
////const blink = "\x1b[5m"
//const reverse = "\x1b[7m"
//const hidden = "\x1b[8m"

const white = "0m"
const red = "31m"
const green = "32m"
const yellow = "33m"
const blue = "34m"
const purple = "35m"
const cyan = "36m"

const BgBlack = "40m"
const BgRed = "41m"
const BgGreen = "42m"
const BgYellow = "43m"
const BgBlue = "44m"
const BgMagenta = "45m"
const BgCyan = "46m"
const BgWhite = "47m"

export const debugLog = (message, object = null) => {
  if (enabled === true && debugEnabled === true) {
    // eslint-disable-next-line no-use-before-define
    outputLog("DEBUG", message, object)
  }
}

export const infoLog = (message, object = null) => {
  // eslint-disable-next-line no-use-before-define
  outputLog("INFO", message, object)
}

export const securityLog = (message, object = null) => {
  // eslint-disable-next-line no-use-before-define
  outputLog("SECURITY", message, object)
}

export const securityLogDebug = (message, object = null) => {
  // eslint-disable-next-line no-use-before-define
  outputLog("SECURITYDEBUG", message, object)
}

export const useEffectLog = (message, object = null) => {
  // eslint-disable-next-line no-use-before-define
  outputLog("USEEFFECT", message, object)
}

export const useEffectLogDebug = (message, object = null) => {

  if (enabled === true && debugEnabled === true) {
    // eslint-disable-next-line no-use-before-define
    outputLog("USEEFFECTDEBUG", message, object)
  }
}

export const storeLog = (message, object = null) => {
  // eslint-disable-next-line no-use-before-define
  outputLog("STORE", message, object)
}

export const storeLogDebug = (message, object = null) => {
  if (enabled === true && logMiddleware === true) {
    // eslint-disable-next-line no-use-before-define
    outputLog("STOREDEBUG", message, object)
  }
}

export const middlewareLog = (message, object = null) => {
  if (enabled === true && logMiddleware === true) {
    // eslint-disable-next-line no-use-before-define
    outputLog("MIDDLE", message, object)
  }
}

export const middlewareLogDebug = (message, object = null) => {
  if (enabled === true && logMiddleware === true) {
    // eslint-disable-next-line no-use-before-define
    outputLog("MIDDLEDEBUG", message, object)
  }
}

export const dalLog = (message, object = null) => {
  // eslint-disable-next-line no-use-before-define
  outputLog("DAL", message, object)
}

export const dalLogDebug = (message, object = null) => {
  if (enabled === true && debugEnabled === true) {
    // eslint-disable-next-line no-use-before-define
    outputLog("DALDEBUG", message, object)
  }
}

export const warningLog = (message, object = null) => {
  // eslint-disable-next-line no-use-before-define
  outputLog("WARNING", message, object)
}

export const errorLog = (message, object = null) => {
  // eslint-disable-next-line no-use-before-define
  outputLog("ERROR", message, object)
}

export const errorLogLocation = (message, location, object = null) => {
  // eslint-disable-next-line no-use-before-define
  outputLog("ERROR", `${message} - ${location}`, object)
}

export const questionLog = (message, object = null) => {
  // eslint-disable-next-line no-use-before-define
  outputLog("QUESTION", message, object)
}

export const noteLog = (message, object = null) => {
  // eslint-disable-next-line no-use-before-define
  outputLog("NOTE", message, object)
}

export const outputLog = (logType, message, object = null) => {
  if (enabled === true) {
    switch (logType) {
      case 'DEBUG': {
        if (logDebug === true && debugEnabled === true) {
          // eslint-disable-next-line no-use-before-define
          log(BgWhite, `🪲  ${logType}`, `${message}`, object)
        }
        break
      }
      case 'NOTE': {
        if (logNote === true) {
          // eslint-disable-next-line no-use-before-define
          drawLineColour(BgWhite)
          // eslint-disable-next-line no-use-before-define
          log(white, `📝  ${logType}`, message, object)
          // eslint-disable-next-line no-use-before-define
          drawLineColour(BgWhite)
        }
        break
      }
      case 'QUESTION': {
        if (logDebug === true && debugEnabled === true) {
          // eslint-disable-next-line no-use-before-define
          log(BgMagenta, logType, message, object)
        }
        break
      }
      case 'STORE': {
        if (logStore === true) {
          // eslint-disable-next-line no-use-before-define
          log(cyan, logType, `💾  ${message}`, object)
        }
        break
      }
      case 'STOREDEBUG': {
        if (logStoreDebug === true && debugEnabled === true) {
          // eslint-disable-next-line no-use-before-define
          log(BgCyan, `🪲💾  ${logType}`, message, object)
        }
        break
      }
      case 'MIDDLE': {
        if (logMiddleware === true) {
          // eslint-disable-next-line no-use-before-define
          log(purple, `🤖  ${logType}`, `${message}`, object)
        }
        break
      }
      case 'MIDDLEDEBUG': {
        if (logMiddleware === true) {
          // eslint-disable-next-line no-use-before-define
          log(BgMagenta, `🪲🤖  ${logType}`, message, object)
        }
        break
      }
      case 'INFO': {
        if (logInfo === true) {
          // eslint-disable-next-line no-use-before-define
          log(BgBlack, `ℹ️  ${logType}`, ` ${message}`, object)
        }
        break
      }

      case 'USEEFFECT': {
        if (logUseEffect === true) {
          // eslint-disable-next-line no-use-before-define
          log(purple, logType, message, object)
        }
        break
      }
      case 'USEEFFECTDEBUG': {
        if (logUseEffect === true && debugEnabled === true) {
          // eslint-disable-next-line no-use-before-define
          log(BgMagenta, logType, message, object)
        }
        break
      }
      case 'SECURITY': {
        if (logSecurity === true) {
          // eslint-disable-next-line no-use-before-define
          log(green, `🔒  ${logType}`, `${message}`, object)
        }
        break
      }
      case 'SECURITYDEBUG': {
        if (logSecurity === true && debugEnabled === true) {
          // eslint-disable-next-line no-use-before-define
          log(BgGreen, `🪲🔒  ${logType}`, message, object)
        }
        break
      }
      case 'DAL': {
        if (logDal === true) {
          // eslint-disable-next-line no-use-before-define
          log(blue, logType, message, object)
        }
        break
      }
      case 'DALDEBUG': {
        if (logDalDebug === true && debugEnabled === true) {
          // eslint-disable-next-line no-use-before-define
          log(BgBlue, logType, message, object)
        }
        break
      }
      case 'WARNING': {
        if (logWarning === true) {
          // eslint-disable-next-line no-use-before-define
          log(BgYellow, `⚠️  ${logType}`, message, object)
        }
        break
      }
      case 'ERROR': {
        if (logErrors === true) {
          // eslint-disable-next-line no-use-before-define
          drawLineColour(BgRed)
          // eslint-disable-next-line no-use-before-define
          log(BgRed, `💀  ${logType}`, message, object)
          // eslint-disable-next-line no-use-before-define
          drawLineColour(BgRed)
        }
        break
      }
      default: {
        if (logOthers === true) {
          // eslint-disable-next-line no-use-before-define
          log(white, logType, message, object)
        }
        break
      }
    }
  }

}

const log = (color, logtype, message, object = null) => {
  console.log(`\u001b[1;${color}[${logtype}] : ${message}\u001b[0m`, (stringifyObjects === true) ? JSON.stringify(object) : object)
}

export const plainLog = (message) => {
  console.log(`\u001b[1;0m${message}`)
}

export const spaceLog = () => {
  console.log(" ")
}

export const drawLineColour = (color) => {
  console.log(`\u001b[1;${color} : --------------------------------------------------\u001b[0m`)
}

export const drawLine = () => {
  console.log("\u001b[1;0m-------------------------------------------------")
}