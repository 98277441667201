/* eslint-disable */
import { cloneDeep } from 'lodash'
//import { saveDraftToLive, saveDirectToLiveObj, saveDraft, createDraftFromLive } from '../s48Data/dalCaller'
import { saveDraftToLive } from '../s48Data/saveDraftToLiveRaw_DAL'
import { saveDirectToLiveObj } from '../s48Data/saveDirectToLiveRaw_DAL';
import { saveDraft } from '../s48Data/saveDraftRaw_DAL_V2'
import { createDraftFromLive } from '../s48Data/createDraftFromLiveRaw_DAL';
import { debugLog, errorLog } from './logger'


export interface s48ObjectInterface {
  userName: string | undefined,
  init: boolean,
  id: string,
  obId: string,
  prvid: string,
  parentId: string,
  objName: string,
  environment: string,
  status: string,
  lock: false,
  nameSpace: "",
  creator: string,
  editor: string,
  sub: string,
  order: number,
  assignedToId: string,
  assignStart: string,
  assignNote: string,
  assignedToGroupId: string,
  assignUrgency: string,
  assignDeadline: string,
  assignedToName: string,
  assignedToGroupName: string,
  assignedById: string,
  properties: any,
  createdAt: string,
  updatedAt: string,
  getHash(): number,
  getPropertyValue(fieldKey: string): string | undefined,
  getProperty(fieldKey: string): string | undefined,
  getPropertiesAsJson(): string,
  setPropertyValue(fieldKey: string, value: string, valueType: string): s48ObjectInterface,
  deleteProperty(fieldKey: string): string,
  saveDraft(): Promise<s48ObjectInterface>,
  saveDirectToLive(): Promise<s48ObjectInterface>,
  saveDraftToLive(): Promise<s48ObjectInterface>,
  createChildDraft(): Promise<s48ObjectInterface>,
  createDraft(): Promise<s48ObjectInterface>,
  getCloneDeep(): s48ObjectInterface
}

const InitializedObject: s48ObjectInterface = {
  userName: "",
  init: false,
  id: "",
  obId: "",
  prvid: "",
  parentId: "",
  objName: "New object",
  environment: "",
  status: "",
  lock: false,
  nameSpace: "",
  creator: "",
  editor: "",
  sub: "",
  order: 0,
  properties: {},
  assignedToId: 'NA',
  assignStart: '',
  assignNote: '',
  assignedToGroupId: '',
  assignUrgency: '',
  assignDeadline: '',
  assignedToName: '',
  assignedToGroupName: '',
  assignedById: 'NA',
  createdAt: "",
  updatedAt: "",
  getHash: function (): number {
    const objString = JSON.stringify(this)
    const seed = 0
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < objString.length; i++) {
      ch = objString.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }
    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  },
  getPropertiesAsJson: function (): string {
    return JSON.stringify(this.properties)
  },
  getPropertyValue: function (fieldKeyIn: string): string | undefined {
    //console.log("Getting value for " + fieldKeyIn)
    try {
      return this.properties[fieldKeyIn.toLowerCase()].value
    } catch (error) {
      return undefined
    }
  },
  setPropertyValue: function (fieldKeyIn: string, valueIn: string, valueTypeIn: string = "STRING"): s48ObjectInterface {
    if (fieldKeyIn === "" || fieldKeyIn === undefined)
      throw new Error("fieldKeyIn must have a value")
    //console.log("setPropertyValue", fieldKeyIn, valueIn, valueTypeIn)
    const fk = fieldKeyIn.replace(/[^a-zA-Z]/g, "").toLowerCase()
    const proObj = {
      value: valueIn,
      valueType: valueTypeIn,
      edited: true,
      editor: this.userName,
      vid: this.id
    }
    this.properties = { ...this.properties, [fk]: proObj }
    return this
  },
  deleteProperty: function (fieldKey: string): string {
    throw new Error("Function not implemented.")
  },
  saveDraft: async function (): Promise<s48ObjectInterface> {
    try {
      var propsJSON = JSON.stringify(this.properties)
      var draftReturn = await saveDraft(this.id, this.nameSpace, this.objName, propsJSON)
      return ConstructObject(draftReturn)
    } catch (error) {
      throw new Error("ERROR")
    }
    throw new Error("ERROR: Should not reach here.")
  },
  saveDirectToLive: async function (): Promise<s48ObjectInterface> {
    try {
      const saveDirectResponse = await saveDirectToLiveObj(this)
      //debugLog("Object interface saveDirectResponse", saveDirectResponse)
      return ConstructObject(saveDirectResponse)
    } catch (error) {
      throw new Error(`ERROR in ObjectInterface.js saveDirectToLive ${(error as Error).message}`)
    }
    throw new Error("ERROR saveDirectToLive: Should not reach here.")
  },
  saveDraftToLive: async function (): Promise<s48ObjectInterface> {
    try {
      const propsJSON = JSON.stringify(this.properties)
      const draftToLiveReturn = await saveDraftToLive(this.id, this.nameSpace, this.objName, propsJSON)
      return ConstructObject(draftToLiveReturn)
    } catch (error) {
      throw new Error("ERROR: ")
    }
    throw new Error("ERROR: Should not reach here.")
  },
  getCloneDeep: function (): s48ObjectInterface {
    return cloneDeep(this)
  },
  createDraft: async function (): Promise<s48ObjectInterface> {
    const draft = await createDraftFromLive(this.obId)
    //console.log(draft)
    return draft?.object
  },
  createChildDraft: function (): Promise<s48ObjectInterface> {
    throw new Error('Function not implemented.')
  },
  getProperty: function (fieldKey: string): string | undefined {
    throw new Error('Function not implemented.')
  }
}

const InitializeProperties = (baseObject: any): s48ObjectInterface => {
  if (baseObject.propertiesData) {
    //console.log(baseObject.propertiesData)
    try {
      //console.log("InitializeProperties")
      let properties = JSON.parse(baseObject.propertiesData)
      try {
        properties = JSON.parse(properties)
      } catch (error) {  //SWALLOW
      }

      //properties = JSON.parse(properties)
      //console.log(properties)
      baseObject.properties = properties
    } catch (error) {
      console.log(error)
    }

  } else {
    console.log("it doesnt think baseObject.propertiesData exists")
    console.log(baseObject.propertiesData)
  }

  return baseObject
}

export const ConstructEmptyObject = (wrapper = null) => {
  try {
    let object = { ...InitializedObject }
    object.init = true
    if (wrapper === null) {
      //debugLog("ConstructEmptyObject: Raw empty object created.", '')
      return object
    } else {
      //const wrappedObj = new wrapper(object)
      //debugLog("ConstructEmptyObject: Wrapping success.", Object.prototype.toString.call(wrapper))
      return object
    }
  } catch (error) {
    const message = (error as Error).message
    errorLog(`s48ObjectInterface. ConstructEmptyObject = (wrapper = null) ${message}`)
    throw new Error(`Snap ${(error as Error).message}`)
  }
}

export const ConstructObject = (baseObject: any): s48ObjectInterface => {
  try {
    let object = { ...InitializedObject }
    //object.userName = authState.state.user
    //console.log("ConstructObject: initalized object:", object)
    if (baseObject.objName === undefined) {
      //console.log("ConstructObject: baseObject.objName === undefined")
      object = {
        ...object,
        ...baseObject.object,
        properties: {}
      }
    } else {
      //console.log("ConstructObject: baseObject.objName IS defnied")
      object = {
        ...object,
        ...baseObject,
        properties: {}
      }
    }
    //console.log("ConstructObject: Init complete", object)
    object = InitializeProperties(object)
    object.init = true
    //console.log("ConstructObject...")
    //console.log(object)
    return object
  } catch (error) {
    return InitializedObject
  }
}

export default s48ObjectInterface

// const InitializeProperties = (baseObject: any): s48ObjectInterface => {
//   if (baseObject.values) {
//     baseObject.values.forEach((kv: s48KeyValueInterface) => {
//       baseObject.properties[kv.fieldKey] = {
//         value: kv.value,
//         valueType: kv.valueType,
//         edited: false
//       }
//     })
//   }
//   return baseObject
// }