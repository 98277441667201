import rootReducer from "./rootReducer"
import { configureStore } from '@reduxjs/toolkit'
const store = configureStore({
  reducer: rootReducer
})

export { store }

// import counterReducer from './list'
// export default configureStore({
//   reducer: {
//     list: counterReducer
//   },
// })