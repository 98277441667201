import { Amplify } from "aws-amplify"
import awsExports from "./aws-exports"
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import Navbar from './screens/Navbar'
import Router from './Routes'

Amplify.configure(awsExports)

function App() {
  const dispatch = useDispatch()
  const store = useSelector((state) => state.adler)
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    // true for mobile device
    //console.log("mobile device")
  } else {
    // false for not mobile device
    //console.log("not mobile device")
  }

  return (
    <BrowserRouter >
      <meta name="mobile-web-app-capable" content="yes" />
      <div className="App"  >
        {/* <Navbar /> */}
        <div className="content" style={{ maxWidth: '600px', margin: 'auto', paddingTop: '0px' }}>
          <Router store={store} dispatch={dispatch} />
        </div>
      </div>
    </BrowserRouter>

  )
}

export default App
