import { Link, useNavigate } from "react-router-dom"
import { listByParentId } from "../s48Data/AdsReactistObjectsByNameSpace_DAL"
import { brandColours } from "../styles/BrandColours"
import { React, useEffect, useState, use } from "react"
import Navbar from "./Navbar"
import { useDispatch, useSelector } from "react-redux"

import moment from 'moment'
import { ConstructEmptyObject } from "../s48/ObjectInterface"
import ReactDOM from 'react-dom'
import Modal from 'react-modal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentDots, faHeart, faEdit, faWindowClose } from '@fortawesome/fontawesome-free-regular'
import { faHeart as solidHeart } from '@fortawesome/fontawesome-free-solid'
import { modal1 } from '../styles/ModalStyles'
import StarRating from "../extracomponents/StarRating"
import Swal from "sweetalert2"
import { faVideoCamera } from "@fortawesome/free-solid-svg-icons"
import { uniqueId } from "lodash"
import axios from "axios"
import { Buffer } from "buffer"
import { setCurrentProcedure, updateProcedure } from "../store"


// display each procedure item here

Modal.setAppElement('#root')
const CustomProcedureItem = (props) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const store = useSelector((state) => state.adler)
    let subtitle
    const [modalIsOpen, setIsOpen] = useState(false)
    const [procedureFb, setProcedureFb] = useState('0')
    const [surgFb, setSurgFb] = useState('0')
    const [scrubFb, setScrubFb] = useState('0')
    const [hospFb, setHospFb] = useState('0')
    const [comment, setComment] = useState('none')
    const [proc, setProc] = useState({})
    let clicked = false
    // const [feedBack, setFeedback] = useState({})

    useEffect(() => {
        console.log("store.currentProcedure,", store.currentProcedure)
    }, [store.currentProcedure])

    const handleClick = (customProc) => {

        if (customProc.order !== '-1') {
            // console.log('handled.... customProc', customProc.obId)
            dispatch(setCurrentProcedure(customProc))
            navigate(`/procedurestepsscreen`, {
                state: { parentId: customProc.obId }

            })
        } else {
            console.log('------------------------------------')
            console.log('disabled!')
            console.log('------------------------------------')
        }
    }

    const handleEditClicked = (customProc) => {
        if (customProc.order !== '-1') {
            dispatch(setCurrentProcedure(customProc))
            navigate(`/editregisteredopscreen`, {
                state: { parentId: customProc.obId }
            })
        } else {
            console.log('------------------------------------')
            console.log('disabled!')
            console.log('------------------------------------')
        }
    }

 function openModal(customProc) {
if (customProc.order !== '-1') {
 dispatch(setCurrentProcedure(customProc))
 console.log('customProc', store.currentProcedure)
 setIsOpen(true)
    } else {
            console.log('------------------------------------')
            console.log('disabled!')
            console.log('------------------------------------')
        }
     }


    // const handleFBClicked = (customProc) => {
    //     openModal()
    // }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = brandColours.adlerBlue
    }
   async function rateProc(val) {
        const proc = store.currentProcedure
console.log('parent...', proc.obId)
        const newobj = ConstructEmptyObject()
        newobj.objName = 'Procedure Feedback'
        newobj.nameSpace = `ADLER.FEEDBACK.PROCEDURE||${proc.obId}`
        newobj.parentId = proc.obId
        newobj.setPropertyValue('rating', val)
        newobj.setPropertyValue('rep', proc.creator)
        newobj.setPropertyValue('surgeon', proc?.properties?.surgeonselect?.value ?? 'not set')
        newobj.setPropertyValue('scrub', proc?.properties?.scrubselect?.value ?? 'not set')
        newobj.setPropertyValue('hospital', proc?.properties?.hospselect?.value ?? 'not set')
        newobj.setPropertyValue('comment', comment)
      
        const savedFeedback = await newobj.saveDirectToLive()
        console.log('proc feedback sent.....', savedFeedback)
    }
    async function rateSurg(val) {
        const proc = store.currentProcedure

        const newobj = ConstructEmptyObject()
        newobj.objName = 'Surgeon Feedback'
        newobj.nameSpace = `ADLER.FEEDBACK.SURGEON||${proc?.properties?.surgeon?.value ?? 'not set'}`
        newobj.parentId = proc.obId
        newobj.setPropertyValue('rating', val)
        newobj.setPropertyValue('rep', proc.creator)
        newobj.setPropertyValue('surgeon', proc?.properties?.surgeonselect?.value ?? 'not set')
        newobj.setPropertyValue('procedure', proc.objName)
        console.log('surgeon newobj..', newobj)
                
        const savedFeedback = await newobj.saveDirectToLive()
        console.log('surg feedback sent.....', savedFeedback)
     }
    async function rateScrub(val) { 
                const proc = store.currentProcedure

        const newobj = ConstructEmptyObject()
        newobj.objName = 'Scrub Feedback'
        newobj.nameSpace = `ADLER.FEEDBACK.SCRUB||${proc?.properties?.scrub?.value ?? 'not set'}`
        newobj.parentId = proc.obId
        newobj.setPropertyValue('rating', val)
        newobj.setPropertyValue('rep', proc.creator)
        newobj.setPropertyValue('scrub', proc?.properties?.scrubselect?.value ?? 'not set')
        newobj.setPropertyValue('procedure', proc.objName)
       
         const savedFeedback = await newobj.saveDirectToLive()
        console.log('scrub feedback sent.....', savedFeedback)
    }
    async function rateHosp(val) { 
        const proc = store.currentProcedure

        const newobj = ConstructEmptyObject()
        newobj.objName = 'Hospital Feedback'
        newobj.nameSpace = `ADLER.FEEDBACK.HOSPITAL||${proc?.properties?.hospital?.value ?? 'not set'}`
        newobj.parentId = proc.obId
        newobj.setPropertyValue('rating', val)
        newobj.setPropertyValue('rep', proc.creator)
        newobj.setPropertyValue('hospital', proc?.properties?.hospselect?.value)
        newobj.setPropertyValue('procedure', proc.objName)
       
        const savedFeedback = await newobj.saveDirectToLive()
        console.log('scrub feedback sent.....', savedFeedback)
    }

    async function defoCloseModal() {
        
        rateProc(procedureFb)
        rateSurg(surgFb)
        rateScrub(scrubFb)
        rateHosp(hospFb)      
  
        setIsOpen(false)
    }

function closeModal() {
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'alertConfirm',
    cancelButton: 'alertCancel'
  },
  buttonsStyling: false
})

swalWithBootstrapButtons.fire({
  title: 'Are you sure?',
  text: "You're feedback will be recorded!",
  icon: 'warning',
  showCancelButton: true,
  confirmButtonText: 'Yes, record it!',
  cancelButtonText: 'Cancel!',
  reverseButtons: true
}).then((result) => {
  if (result.isConfirmed) {
    swalWithBootstrapButtons.fire(
      'Submitted!',
      'Your feedback has been recorded.',
      'success'
    )
    defoCloseModal()
  } else if (
    
    result.dismiss === Swal.DismissReason.cancel
  ) {
    // swalWithBootstrapButtons.fire(
    //   'Cancelled',
    //   'Your imaginary file is safe :)',
    //   'error'
    // )
    console.log('returned')
  }
})
 }
    
function cancelModal() {
setIsOpen(false)
}


const navOutExec = async (customProcedure) => { 
    console.log('hjkbcskjdbcvkjas')
    const pf = 'gydfudchmgdytdchjgfvjgfj'
    const preurl = `https://d31ptou7wtm6zx.cloudfront.net/as-videochat/build/callapp.html`
const tempurl = `https://adlerbucket95720-dev.s3.eu-west-1.amazonaws.com/webCam/temporaryBuild/build/callapp.html`
const authString = `Basic  ${Buffer.from('admin:pass1').toString('base64')}`
const adpass = 'admin103:gdfckutyr6756tiyt97iyt7t8yb'

const rndCodeGen = (length) => {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let counter = 0
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
      counter += 1
    }
    return result
}

console.log('auth is ..... : ', authString)
  const passcode = rndCodeGen(5) + customProcedure.obId

// sync with graphql admin
console.log('syncing ....', customProcedure.objName)

        const procedure = customProcedure.getCloneDeep()
        procedure.setPropertyValue('chatpasscode', passcode)
        const savedItem = await procedure.saveDirectToLive()

        dispatch(updateProcedure(savedItem))
        dispatch(setCurrentProcedure(savedItem))
//////////////////////////

const url = `https://${adpass}@d31ptou7wtm6zx.cloudfront.net/as-videochat/build/callapp.html?pc=${passcode}`

const sendfetch = async () => { 
    await fetch(url, { 
        mode: 'no-cors',
        headers: {
            authorization: authString 
        }
     })
        .then(response => console.log(response))
        .catch((error) => {
            console.error('Error sending credentials to S3:', error.message)
        })
  }

 // sendfetch()

   // https://adlerbucket95720-dev.s3.eu-west-1.amazonaws.com/webCam/build/callapp.html
    //navigate(`/amochatscreen`)
// The username and password
// DO NOT store credentials in your JS file like this
//const auth = Buffer.from(`${username}:${password}`).toString('Base64')

// Authenticate (dummy API)

// https://adlerbucket95720-dev.s3.eu-west-1.amazonaws.com/webCam/temporaryBuild/build/callapp.html
// async function sendCredentialsToS3(usernam, passwo) {
//     const url = 'https://adlerbucket95720-dev.s3.eu-west-1.amazonaws.com/webCam/temporaryBuild/build/callapp.html' // Replace with your S3 URL
//     const data = {
//         username:usernam,
//         password:passwo
//     }
  
//     try {
//       const response = await fetch(url, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         body: JSON.stringify(data)
//       })
  
//       if (!response.ok) {
//         throw new Error('Failed to send credentials')
//       }
  
//       const responseData = await response.json()
//       // Handle the response data as needed
//       console.log('Response from S3:', responseData)
//     } catch (error) {
//       console.error('Error sending credentials to S3:', error.message)
//     }
//   }
  
//   // Example usage:
//   sendCredentialsToS3(usern, passw)
  

    // axios.post('https://adlerbucket95720-dev.s3.eu-west-1.amazonaws.com/webCam/build/callapp.html', pf)
   
     window.location.href = url
   // window.location.href = "../../html_build/callapp.html"

 }

 const navOut = (customProcedure) => {
    if (clicked) {
       console.log('already clicked!!!!')
    } else {
        clicked = true
        navOutExec(customProcedure)
    }
}
    if (store.procedures) {
        return (
            <>             
                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={modal1}
                    contentLabel="Feedback Modal"
                >
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                    <FontAwesomeIcon size="xl" icon={faWindowClose} onClick={() => cancelModal()}  />
                    </div>
                    <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Feedback</h2>


                    <form>
                        <div style={{marginTop:'10px'}}>Rate this procedure</div>
                        <StarRating handleClick={(value) => setProcedureFb(value)}/>                      
                        <div>Rate this Surgeon</div>
                        <StarRating handleClick={(value) => setSurgFb(value)}/>               
                        <div>Rate this Theatre Practitioner</div>
                        <StarRating handleClick={(value) => setScrubFb(value)}/>
                        <div>Rate this Hospital</div>
                        <StarRating handleClick={(value) => setHospFb(value)}/>
                        <div>Leave a comment</div>
                        <textarea style={{width:'100%'}} type="text" name="comment" placeholder="What is your feedback?" onChange={(value) => setComment(value.target.value)}/>

                    </form>
                    <div style={{color: brandColours.adlerAlert, textAlign:'center', padding:'10px'}} onClick={() => { closeModal() }}>Submit</div>
                </Modal>
                <div className="proc-list">
                    {store.procedures.map((customProcedure) => {
                        const surgeonselect = JSON.parse(customProcedure.properties.surgeonselect?.value ?? '{"value":"", "label":""}')
                        const repselect = JSON.parse(customProcedure.properties.repselect?.value ?? '{"value":"", "label":""}')
                        

                        return (
                            <div className="list-preview" key={customProcedure.id} >  
                            
                                <h2 onClick={() => handleClick(customProcedure)} style={customProcedure.order === '-1' ? { color: "lightgrey" } : { color: brandColours.adlerBlue }}>{customProcedure.objName}</h2>
                                <div style= {{display:"flex", flexDirection:'row', justifyContent:'space-between'}}>
                                <h3 style={customProcedure.order === '-1' ? { color: "lightgrey" } : { color: "black" }}>{customProcedure.properties.status?.value ?? ''}</h3>
                                <FontAwesomeIcon size="xl" icon={faVideoCamera} onClick={() => { navOut(customProcedure) }} />
                            </div> 
                                <div className="listItem">
                                    <div className="columnLeft" onClick={() => handleClick(customProcedure)}>
                                        <p> {customProcedure.properties.hospital?.value?.value ?? ''}</p>
                                        <p> {moment(customProcedure.properties.opdate?.value).format("dddd, MMMM Do YYYY") ?? ''}</p>
                                    </div>
                                    <div className="columnMiddle" onClick={() => handleClick(customProcedure)}>
                                        <p> {surgeonselect.label}</p>
                                        <p> {repselect.label}</p>

                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <FontAwesomeIcon size="xl" icon={faHeart} onClick={() => { console.log(' set as favourite') }} />
                                    <FontAwesomeIcon size="xl" icon={faEdit} onClick={() => handleEditClicked(customProcedure)} />

                                    <FontAwesomeIcon size="xl" icon={faCommentDots} onClick={() => { openModal(customProcedure) }} />
                                </div></div>
                            )
                        })
                    }
                </div >
            </>
        )
    } else {
        //console.log("no list items")
        return (
            <>
                <h2>This procedure is not available for this demo</h2>
            </>
        )
    }
}

export default CustomProcedureItem