import BlogList from "./BlogList"
import { setMocking, listRegisteredProceduresPending } from "../store"
import { useEffect } from "react"
import { isPending } from "@reduxjs/toolkit"
import { brandColours } from '../styles/BrandColours'
import Navbar from "./Navbar"
import { useNavigate } from 'react-router-dom'
//show the 
const EvalMenu = (props) => {
    const { store, logOutput, dispatch } = props
    
    const navigate = useNavigate()

function clicked() {
    alert('clicked')
    navigate('/evalquestion')
}
    return (
        <div className="container" >
            <Navbar pageTitle="Evaluations" />
            <div className='blueButton' onClick={clicked}>ha ha! </div>
        </div>
    )
}

export default EvalMenu