import { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentDots, faHeart, faEdit, faWindowClose } from '@fortawesome/fontawesome-free-regular'
import SyncIcon from '@mui/icons-material/Sync'
import SyncRoundedIcon from '@mui/icons-material/SyncRounded'
import { brandColours } from '../styles/BrandColours'
import Navbar from "./Navbar"
import { setCurrentProcedure, updateProcedure } from "../store"
import Swal from "sweetalert2"
// import { useSearchParamsState } from "react-use-search-params-state";


const StepDisplay = () => {

    const store = useSelector((state) => state.adler)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isDisabled, setIsDisabled] = useState(false)

    useEffect(() => {
        console.log(store.currentStep)

    }, [store.currentStep])

    const handleSync = async () => {
        setIsDisabled(true)
        console.log('syncing ....', store.currentStep)

        const procedure = store.currentProcedure.getCloneDeep()
        procedure.setPropertyValue('synchedstepid', store.currentStep.order)
        const savedItem = await procedure.saveDirectToLive()

        dispatch(updateProcedure(savedItem))
        dispatch(setCurrentProcedure(savedItem))
        Swal.fire({
            customClass: {
                confirmButton: 'alertCancel'
            },
            title: "Synchronised!",
            icon: 'success',
            confirmButtonText: 'OK',
            buttonsStyling: false
        })
        setIsDisabled(false)

    }
    return (
        <>
            <Navbar pageTitle="Sync this step?" />
            <div className="container">
                <h3 style={{ color: "grey", marginBottom: '10px' }}>{store.currentStep.objName}</h3>
                <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between' }}> <h1>{store.currentStep.properties.instruction.value}</h1>
                    <SyncRoundedIcon style={isDisabled ? { fill: 'grey', fontSize: '4em' } : { fill: brandColours.adlerAlert, fontSize: '4em' }} onClick={isDisabled ? console.log('disabledButton') : handleSync} />
                </div>
                <div className="stepcontainer">
                    <div className="topsection">
                        <p>{store.currentStep.properties.detail.value}</p>

                    </div>
                    <div className="middlesection">
                        <h3>Surgeon's view</h3>
                        <div className="imageHolder">
                            <img src={store.currentStep.properties.surgurl.value} alt="" width="96%" style={{ margin: "2%" }} />
                        </div>
                        <h3>Practitioner's view</h3>
                        <div className="imageHolder">
                            <img src={store.currentStep.properties.scruburl.value} alt="" width="96%" style={{ margin: "2%" }} />
                        </div>
                    </div>
                    <div className="bottomsection" style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                        {isDisabled ? <div onClick={console.log('disabled button')} style={{ textAlign: 'center', color: brandColours.adlerGrey, fontSize: '22pt' }}>Please wait  <SyncRoundedIcon style={{ fill: brandColours.adlerGrey, fontSize: '1em' }} onClick={() => console.log('customProcedure')} /> </div> : <div onClick={handleSync} style={{ textAlign: 'center', color: brandColours.adlerAlert, fontSize: '22pt' }}>Synchronise  <SyncRoundedIcon style={{ fill: brandColours.adlerAlert, fontSize: '1em' }} onClick={() => console.log('customProcedure')} /> </div>}
                    </div>
                </div>
            </div>

        </>
    )
}

export default StepDisplay